import React, { useState } from "react";
import { showToast } from "../../Components/Toast";
import { useHistory } from "react-router-dom";
import { ApiCall } from "../../Redux/Actions/ApiCall";
import { urls } from "../../Utils/url";
import { SyncLoader } from "react-spinners";
export const CodeTexts = ({ mobile }) => {
  return (
    <div className="CodeTextsRecive">
      <span>{`لطفا کد ارسال شده به شماره ${mobile} را وارد نمایید`}</span>
    </div>
  );
};
const verifyCode = async (
  phoneNumber,
  history,
  code,
  setLoading,
  setLogin,
  setToken,
  setRegister,
  setHasVideo,
  setDriverConfirmed
) => {
  const body = { mobile_number: phoneNumber, code: code };
  ApiCall(
    "post",
    urls.Login,
    body,
    "Login",
    null,
    function (e) {
      setLogin(true);
      setLoading(false);
      if (!e.data) {
        setToken(null);
        setRegister(null);
        setHasVideo(false);
        history.push("/steps");
      } else if (e.data.user_role_driver.taxi_driver_status_id === 1) {
        setToken(e.data.access_token);
        setRegister(e.data);
        if (e.data.user_role_driver.driver_doc.driver_video !== null)
          setHasVideo(true);
        history.push("/steps");
      } else {
        setToken(null);
        setHasVideo(false);
        setRegister(null);
        setDriverConfirmed(true);
        history.push("/status");
        // history.push("/steps");
      }
    },
    function (e) {
      setLoading(false);
    }
  );
};
export const onClick = (
  code,
  history,
  phone,
  setLoading,
  setLogin,
  setToken,
  setRegister,
  setHasVideo,
  setDriverConfirmed
) => {
  setLoading(true);
  if (code === "" || code.includes(" ") || code.length < 5) {
    setLoading(false);

    showToast("لطفا کد را وارد نمایید");
  } else
    verifyCode(
      phone,
      history,
      code,
      setLoading,
      setLogin,
      setToken,
      setRegister,
      setHasVideo,
      setDriverConfirmed
    );
};

export const Button = ({
  code,
  phone,
  setLogin,
  setToken,
  setRegister,
  setHasVideo,
  setDriverConfirmed,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  return (
    <div
      className="BlueButton"
      onClick={() =>
        onClick(
          code,
          history,
          phone,
          setLoading,
          setLogin,
          setToken,
          setRegister,
          setHasVideo,
          setDriverConfirmed
        )
      }
    >
      {loading ? (
        <SyncLoader
          size={10}
          //size={"150px"} this also works
          color={"white"}
          loading={true}
        />
      ) : (
        <span>ارسال</span>
      )}
    </div>
  );
};
