import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Dot } from "../../assets/icons/DotLine.svg";
export const Items = [
  { text: "بارگذاری مدارک", number: "2" },
  { text: "اطلاعات فردی", number: "1" },
  { text: "قوانین و مقررات", number: "3" },
];

export const Documents = () => {
  return (
    <div className="documents">
      <span>مدارک لازم برای ثبت نام</span>
      <div>
        <span>گواهینامه</span>
        <span>کارت خودرو</span>
        <span>کارت ملی</span>
        <span>بیمه‌نامه خودرو</span>
      </div>
    </div>
  );
};

export const Texts = () => {
  return (
    <div className="StepsText">
      <span>در کوتاه‌ترین زمان راننده آرمش شوید</span>
      <span>طی 4 مرحله ساده و رایگان راننده آرمش شوید و شروع به کسب درآمد کنید</span>
    </div>
  );
};

export const StepItem = ({ text, number }) => {
  return (
    <div className="StepItemDiv">
      <div className="StepItem">
        <div className="StepItemCircle">
          <span>{number}</span>
        </div>
        <Dot />
      </div>
      <span>{text}</span>
    </div>
  );
};

export const Button = () => {
  const history = useHistory();
  return (
    <div className="BlueButton" onClick={() => history.push("/info")}>
      <span>شروع ثبت نام</span>
    </div>
  );
};
