import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { ApiCall } from "../../Redux/Actions/ApiCall";
import { urls } from "../../Utils/url";
import UploadCard from "./UploadFile";
export const UploadFrom = ({ ...props }) => {
  const [profile, setProfile] = useState("");
  const [DriverLic1, setDriverLic1] = useState("");
  const [DriverLic2, setDriverLic2] = useState("");
  const [carCard2, setCarCard2] = useState("");
  const [carCard1, setCarCard1] = useState("");
  const [nationalCard1, setNationalCard1] = useState("");

  useEffect(() => {
    let file = props.files;
    const info = props.info;
    file.profile_img = profile;
    file.driving_licence_img1 = DriverLic1;
    file.driving_licence_img2 = DriverLic2;
    file.vehicle_card_img2 = carCard2;
    file.vehicle_card_img1 = carCard1;
    file.national_card_img1 = nationalCard1;
    props.setFiles({ ...file, ...info });
  }, [DriverLic1, DriverLic2, carCard2, carCard1, profile, nationalCard1]);

  useEffect(() => {
    const data = props.info;
    if (data.user_role_driver && data.user_role_driver.driver_doc) {
      setProfile(`https://5e5687df26b6a300112a0f98.liara.space/armash/taxi/driver/${data.image_location}`);
      setDriverLic1(
        `https://5e5687df26b6a300112a0f98.liara.space/armash/taxi/driver/${data.user_role_driver.driver_doc.driving_licence_img1}`
      );
      setDriverLic2(
        `https://5e5687df26b6a300112a0f98.liara.space/armash/taxi/driver/${data.user_role_driver.driver_doc.driving_licence_img2}`
      );
      setCarCard2(
        `https://5e5687df26b6a300112a0f98.liara.space/armash/taxi/driver/${data.user_role_driver.driver_doc.vehicle_card_img2}`
      );
      setCarCard1(
        `https://5e5687df26b6a300112a0f98.liara.space/armash/taxi/driver/${data.user_role_driver.driver_doc.vehicle_card_img1}`
      );

      setNationalCard1(
        `https://5e5687df26b6a300112a0f98.liara.space/armash/taxi/driver/${data.user_role_driver.driver_doc.national_card_img1}`
      );
    }
  }, [props.info]);
  return (
    <div className="UploadContain">
      <UploadCard
        setRegister={props?.setRegister}
        token={props?.token}
        isUploaded={props?.isUploaded}
        setIsUploaded={props?.setIsUploaded}
        file={profile}
        setFile={setProfile}
        id1="profile_img"
        text={"عکس پروفایل"}
        twoImage={false}
      />
      <UploadCard
        setRegister={props?.setRegister}
        token={props?.token}
        isUploaded={props?.isUploaded}
        setIsUploaded={props?.setIsUploaded}
        id1="driving_licence_img1"
        id2="driving_licence_img2"
        twoImage={true}
        file={DriverLic1}
        setFile={setDriverLic1}
        file2={DriverLic2}
        setFile2={setDriverLic2}
        text={"تصویر گواهینامه"}
      />
      <UploadCard
        setRegister={props?.setRegister}
        token={props?.token}
        isUploaded={props?.isUploaded}
        setIsUploaded={props?.setIsUploaded}
        id1="vehicle_card_img1"
        id2="vehicle_card_img2"
        twoImage={true}
        file={carCard2}
        setFile={setCarCard2}
        file2={carCard1}
        setFile2={setCarCard1}
        text={"تصویر کارت خودرو"}
      />
      <UploadCard
        setRegister={props?.setRegister}
        token={props?.token}
        isUploaded={props?.isUploaded}
        setIsUploaded={props?.setIsUploaded}
        id1="national_card_img1"
        twoImage={false}
        file={nationalCard1}
        setFile={setNationalCard1}
        text={"تصویر کارت ملی"}
      />
    </div>
  );
};

export const checkFiles = (data, history, isUploaded) => {
  let felteredData = data;
  if (data.user_role_driver) {
    let keys2 = [
      "driving_licence_img1",
      "driving_licence_img2",
      "vehicle_card_img2",
      "vehicle_card_img1",
      "national_card_img1",
    ];
    let keys = ["profile_img"];

    const filteredImages = Object.entries(data?.user_role_driver?.driver_doc || {})
      .filter((i) => keys2.includes(i[0]))
      .filter((item) => !item[1].includes("https"))
      .reduce((obj, key) => {
        obj[key[0]] = data[key[0]];
        return obj;
      }, {});
    const filteredImages2 = Object.entries(data)
      .filter((i) => keys.includes(i[0]))
      .filter((item) => !item[1].includes("https"))
      .reduce((obj, key) => {
        obj[key[0]] = data[key[0]];
        return obj;
      }, {});

    felteredData = { ...filteredImages, ...filteredImages2 };
  }
  // setLoading(false);
  history.push("/terms");
  // if (Object?.values(felteredData)?.includes("") || Object?.values(felteredData)?.length != 7) {
  //   showToast("لطفا فایل های مورد نظر را انتخاب نمایید نمایید");

  //   return false;
  // } else history.push("/terms");
};
export const register = async (data, history, setToken, setLoading, setRegister) => {
  const body = { ...data };
  ApiCall(
    "post",
    urls.Register,
    body,
    "Upload",
    null,
    function (e) {
      console.log("register info", e.data);
      setLoading(false);
      setRegister(e.data);
      setToken(e.data.access_token);
      history.push("/terms");
    },
    function (e) {
      console.log(e);
      setLoading(false);
    }
  );
};
export const update = async (data, history, setLoading, token, setRegister) => {
  const body = { ...data };

  ApiCall(
    "post",
    urls.Update,
    body,
    "update",
    token,
    function (e) {
      const Data = {
        first_name: e.data.first_name,
        last_name: e.data.last_name,
        mobile_number: e.data.mobile_number,
        birth_date: e.data.birth_date,
        address: e.data.user_role_driver.address,
        national_code: e.data.user_role_driver.national_code,
        shenas_no: e.data.user_role_driver.shenas_no,
        father_name: e.data.user_role_driver.father_name,
        postal_code: e.data.user_role_driver.postal_code,
      };
      setLoading(false);
      setRegister({ ...e.data, ...Data });
      history.push("/upload");
    },
    function (e) {
      console.log(e);
      setLoading(false);
    }
  );
};
export const Button = ({ Data, loading, isUploaded }) => {
  const history = useHistory();
  return (
    <div className="BlueButton" onClick={() => !loading && checkFiles(Data, history, isUploaded)}>
      {loading ? (
        <SyncLoader
          size={10}
          //size={"150px"} this also works
          color={"white"}
          loading={true}
        />
      ) : (
        <span>تایید و ادامه</span>
      )}
    </div>
  );
};
