import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, UploadFrom } from "../../Components/UploadPhotos";
import { checkFiles } from "../../Components/UploadPhotos/index.js";
import { setToken } from "../../Redux/Actions/Authentication";
import { registerData } from "../../Redux/Actions";
const Upload = ({ ...props }) => {
  const [files, setFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState([]);
  const history = useHistory();
  return (
    <div className="container">
      <div className="mobileContainer">
        <div className="MobileHeader">
          <span onClick={() => history.push("/info")}>اطلاعات فردی</span>
          <span>{"<"}</span>
          <span
            onClick={() => history.push("/record")}
            className={"selectedtext"}
          >
            بارگذاری مدارک
          </span>
          <span>{"<"}</span>
          <span
            onClick={() =>
              checkFiles(
                files,
                history,
                setToken,
                setLoading,
                props.token,
                props.setRegister
              )
            }
          >
            قوانین و مقررات
          </span>
          <span>{"<"}</span>
          <span

          // onClick={() => history.push("/record")}
          >
            احراز هویت
          </span>
        </div>
        <UploadFrom
          isUploaded={isUploaded}
          setIsUploaded={setIsUploaded}
          files={files}
          setFiles={setFiles}
          token={props.token}
          info={props.registerInfo}
          setRegister={props?.setRegister}
        />
        <Button
          isUploaded={isUploaded}
          Data={files}
          setToken={props.setToken}
          loading={loading}
          setLoading={setLoading}
          token={props.token}
          setRegister={props.setRegister}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    registerInfo: state.auth.registerData,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (token) => dispatch(setToken(token)),
    setRegister: (data) => dispatch(registerData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
