export const errorHandling = error => {
  let result = "An error occurred";
  switch (error) {
    case 401:
      result = "Unauthorized!";
      break;
    case 405:
      result = "Method Not Allowed!";
      break;
    case 422:
      result = "اطلاعات وارد شده صحیح نمی باشد."; //'The given data was invalid.'
      break;
    case 500:
      result = "Whoops, looks like something went wrong";
      break;
    case 1001:
      result = "با این شماره قبلا یک کاربر ثبت شده است!"; //'User With This Mobile Exist!'
      break;
    case 1004:
      result = "کاربری با این شماره وجود ندارد!"; //"User With This Mobile Does Not Exist!"
      break;
    case "RG1002":
      result = "پس از 3 دقیقه می توانید کد تایید دریافت نمایید";
      break;
    case 1010:
      result = "کاربری با این نام کاربری یافت نشد!"; //"User With This Username Does Not Exist!"
      break;
    case 1090:
      result = "دسترسی شما محدود شده است"; //"You Are Blocked By This User!"
      break;

    default:
      result = "خطایی رخ داده است!";
      break;
  }
  return result;
};
