// import  AppNavigator from "../../Navigation/navigation";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  mobile: "",
  userInfo: {}
};

function auth(state = initialState, action) {
  switch (action.type) {
    case "GET_MOBILE":
      return { ...state, mobile: action.mobile };
    case "CODE":
      return {
        ...state,
        code: action.code,
        mobile: action.mobile
        // id: action.id
      };
    case "REGISTER_DATA":
      return {
        ...state,
        registerData: action.data
      };
    case "HAS_VIDEO":
      return {
        ...state,
        hasVideo: action.data
      };
    case "GET_USER":
      return { ...state, userInfo: action.userInfo };
    case "LOGIN":
      return { ...state, isLogin: action.isLogin };
    case "TOKEN":
      return { ...state, token: action.token };
    case "STATUS":
      return { ...state, status: action.status };
    case "RATIO":
      return { ...state, ratio: action.ratio };

    default:
      return state;
  }
}

function init(state = initialState, action) {
  switch (action.type) {
    case "START_APP":
      return {
        ...state,
        isLoading: action.isLoading
      };
    case "LOGIN_PAGE":
      return { ...state, loginOpen: action.open };
    case "INIT":
      return {
        ...state,
        userInfo: action.userInfo,
        token: action.token
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        userInfo: action.userInfo
      };

    default:
      return state;
  }
}

function params(state = initialState, action) {
  switch (action.type) {
    case "STATIONS":
      return {
        ...state,
        stations: action.payload
      };
    case "CITIES":
      return {
        ...state,
        cities: action.cities
      };
    case "DRIVER_CONFIRM":
      return {
        ...state,
        confirmed: action.bool
      };
    case "STATION_DETAILS":
      return {
        ...state,
        stationDetails: action.payload
      };
    case "USER_STATIONS":
      return {
        ...state,
        userStations: action.payload
      };
    case "UNIT_MODAL":
      return {
        ...state,
        visibleUnitModal: action.payload
      };
    case "SET_UNIT":
      return {
        ...state,
        unit: action.payload
      };

    default:
      return state;
  }
}

function persistedParams(state = { ...initialState, bills: [] }, action) {
  switch (action.type) {
    case "SET_UNIT":
      return {
        ...state,
        unit: action.payload
      };
    case "ADD_BILL":
      return {
        ...state,
        bills: [...action.bill]
      };
    case "REMOVE_BILL":
      return {
        ...state,
        bills: state.bills.filter(i => i.billId !== action.billId)
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "persistedParams"]
};
const AppReducer = persistCombineReducers(persistConfig, {
  init,
  auth,
  params,
  persistedParams
});

export default AppReducer;
