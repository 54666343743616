import React from "react";
import { MobileHeader } from "../../Components/Common/MobileHeader";
import { Button, RecordTexts, ButtonExit } from "../../Components/RecordVideo";
import { connect } from "react-redux";
import { login, setToken } from "../../Redux/Actions/Authentication";
import { registerData, setHasVideo } from "../../Redux/Actions";

const Record = ({ ...props }) => {
  return (
    <div className="container">
      <div className="mobileContainer">
        <MobileHeader selected={4} />
        <RecordTexts hasVideo={props.hasVideo} />
        {props.hasVideo && (
          <ButtonExit
            setLogin={props.setLogin}
            setToken={props.setToken}
            setRegister={props.setRegister}
            setHasVideo={props.setHasVideo}
          />
        )}
        <Button />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    hasVideo: state.auth.hasVideo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLogin: islogin => dispatch(login(islogin)),
    setToken: token => dispatch(setToken(token)),
    setRegister: data => dispatch(registerData(data)),
    setHasVideo: data => dispatch(setHasVideo(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Record);
