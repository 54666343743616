import React, { useState } from "react";
import CodeInput from "../../Components/CodeInput/CodeInput";
import { connect } from "react-redux";
import { Button, CodeTexts } from "../../Components/CodeInput/Components";
import { HeaderImage } from "../../Components/ReceiveCode";
import { login, setToken } from "../../Redux/Actions/Authentication";
import {
  registerData,
  setHasVideo,
  setDriverConfirmed
} from "../../Redux/Actions";

const Verify = ({ ...props }) => {
  const [code, setCode] = useState("");

  return (
    <div className="container">
      <div className="mobileContainer">
        <HeaderImage />
        <CodeTexts mobile={props.mobile} />
        <CodeInput code={code} setCode={setCode} />
        <Button
          code={code}
          phone={props.mobile}
          setLogin={props.setLogin}
          setToken={props.setToken}
          setRegister={props.setRegister}
          setHasVideo={props.setHasVideo}
          setDriverConfirmed={props.setDriverConfirmed}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  mobile: state.auth.mobile
});
const mapDispatchToProps = dispatch => {
  return {
    setLogin: islogin => dispatch(login(islogin)),
    setToken: token => dispatch(setToken(token)),
    setRegister: data => dispatch(registerData(data)),
    setHasVideo: data => dispatch(setHasVideo(data)),
    setDriverConfirmed: data => dispatch(setDriverConfirmed(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Verify);
