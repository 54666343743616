import React, { useState, useEffect } from "react";
import { MobileHeader } from "../../Components/Common/MobileHeader";
import {
  RecordTexts,
  Button,
  VideoButton,
  UploadVideoCard
} from "../../Components/Recording";
import VideoExample from "../../Components/Recording/VideoRecord";
import { connect } from "react-redux";
import { login, setStatus } from "../../Redux/Actions/Authentication";

const Recording = ({ ...props }) => {
  const [uploadFile, setUploadFile] = useState("");
  const [player, setPlayer] = useState("");
  const [stopedRecord, setStopedRecord] = useState("");
  const [selectedFile, setSelectedFile] = useState(false);
  const [error, setError] = useState(false);

  return (
    <div className="container">
      <div className="mobileContainer">
        <MobileHeader selected={4} />
        <RecordTexts />
        {!selectedFile && !error && (
          <VideoExample
            setUploadFile={setUploadFile}
            setPlayer={setPlayer}
            setStopedRecord={setStopedRecord}
            setError={setError}
          />
        )}
        <div
          style={{
            position: "absolute",
            bottom: window.innerHeight * 0.1,
            display: "flex",
            flexDirection: "row",
            width: "92%",
            alignItems: "center",
            justifyContent: "space-between",
            left: "auto",
            right: "auto"
          }}
        >
          {!error && !selectedFile && (
            <VideoButton player={player} stopedRecord={stopedRecord} />
          )}
          <UploadVideoCard
            text={"ویدئو احراز هویت"}
            id1={"VideoFile"}
            file={uploadFile}
            setFile={setUploadFile}
            selectedFile={selectedFile || error}
            setSelectedFile={setSelectedFile}
          />
        </div>
        {(error || selectedFile) && (
          <>
            <span
              style={{
                marginBottom: window.innerHeight * 0.2,
                textAlign: "center",
                width: "85%",
                color: uploadFile === "" ? "red" : "green"
              }}
            >
              {uploadFile === ""
                ? `متاسفانه مرورگر شما قادر به ضبط ویدئو نمی باشد. لطفا از مرورگر
              دیگری استفاده نمایید و یا می توانید ویدئو ضبط شده خود را انتخاب
              کنید`
                : "فایل انتخابی شما آماده ارسال است"}
            </span>
          </>
        )}
        <Button
          Data={uploadFile}
          token={props.token}
          setStatus={props.setStatus}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setStatus: status => dispatch(setStatus(status))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Recording);
