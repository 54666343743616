export function addBill(bill) {
  return { type: "ADD_BILL", bill };
}
export function removeBill(billId) {
  return { type: "REMOVE_BILL", billId };
}
export function registerData(data) {
  return { type: "REGISTER_DATA", data };
}
export function setHasVideo(data) {
  return { type: "HAS_VIDEO", data };
}
export function setCities(cities) {
  return { type: "CITIES", cities };
}
export function setDriverConfirmed(bool) {
  return { type: "DRIVER_CONFIRM", bool };
}

export const clearPersist = () => {
  return dispatch => {
    dispatch({
      type: "LOGIN",
      isLogin: false
    });
    dispatch({
      type: "TOKEN",
      token: false
    });
  };
};
