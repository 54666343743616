import React from "react";
import { useHistory } from "react-router-dom";

export const texts = {
  header: "قوانین و مقررات تصویبی و ابلاغی آرمش حاکم برای (سفیران همکار)",
  body: `
  یکی از امکانات و ظرفیتهای نرم افزارآرمش که استفاده انحصاری آن در اختیار شرکت کیان گستر آرامش می باشد، جابجایی مسافران و حمل و نقل بار و ارسال و مراسلات مرسوله بوده که بمنظور تسریع و تسهیل در این بخش طراحی شده است ومطابق شرایط مرقومه ذیل،خدمات مربوطه را ارائه میدهد لذا باقبول این شروط، قراردادی الزام آورفیمابین شرکت و شما راننده عزیز که از این پس سفیر خوانده می شوید حاکم خواهدبود. بنابراین انتظار میرود به دقت نظر مراتب را مطالعه فرمائید.
  نصب واستفاده ازنرم افزار به منزله قبول و پذیرش تمامی شرایط بوده و طرح هرگونه ادعا یا ایراد و اشکال اعتراضی در حال و آینده را بلا اثر و سلب و ساقط می نماید.
  تذکر:هرگونه عدد،کلمه،علامت، تصویرو یا نشان الکترونیکی که مثبِت و مؤید هویت امضاءکننده باشدوبه این سند یا سایر اسناد صادره در این زمینه ملحق شود به منزله امضای دیجیتالی بوده وهمانند امضائات دستنویس،واجد اعتبار و اثر حقوقی خواهد بود وصاحب امضاء را به اجرای مفاد سند مستلزم نموده و حق طرح ادعا یا اعتراضات بعدی را از خود اسقاط می نماید.
  شرکت به عنوان طراح ومالک مادی و معنوی نرم‌افزار آرمش، طبق شرایط متعینه،خدمات نرمافزاری پیش بینی شده را به سفیر ارائه داده و هدف اصلی فعالیت شرکت، صرفا تسریع و تسهیل درد سترسی به مسافر و محموله ومرسولات و ارتباط آسان با سفیر می باشد و شخصاً و رأساً هیچگونه فعالیتی مبنی بر حمل و نقل ارائه نمی دهد.
  ماده اول : شرایط عمومی همکاری در شرکت:
  1)حداقل سن ۱۸ سال تمام شمسی
  2) دارا بودن گواهینامه معتبر موتورسیکلت و خودرو واجد اعتبار صلاحیت در جابجایی مسافران و تسلط و مهارت لازم و قابل توجه در امر رانندگی با وسایط نقلیه مذکور
  3)عدم اشتهار به فساد اخلاقی
  4)اعتیاد نداشتن به هرگونه موادمخدر و الکلی و روان گردان سنتی و صنعتی
  5)بهره مندی ازیک دستگاه تلفن همراه مجهزبه سیستم عامل اندروید ۴ به بالا ودسترسی به اینترنت پرسرعت
  ماده دوم: مدارک مورد نیاز همکاری:
  ۱) گواهینامه
  2)کارت ملی ،بیمه نامه معتبر شخص ثالث وسیله نقلیه ،سند مالکیت وسیله نقلیه
  3) داشتن معاینه فنی
  تبصره: اطلاعات مثبوت در ابناد 1 و 2، باید با اطلاعات فردی وارد شده در مرحله اول ثبت نام، کاملا مطابقت داشته باشد.(شخص ثبت نام کننده مالک وسیله نقلیه باشد.)بدیهی است پاسخگوئی درخصوص مغایرت مشخصات مثبوتی که بعداً کاشف به عمل آید متوجه فرد متقاضی همکاری خواهد بود.
  قرارداد
  ۱)نظر به اینکه مسافران و صاحبان محموله از طریق نرم افزار آرمش به سفیر معرفی می‌شوند، لذا سفیران متعهد می شوند، ازهر مبلغ دریافتی(کرایه سفر) و حمل و نقل بار و ارسال و مراسلات مرسوله بابت خدماتی که به اشخاص متقاضی خدمات ارائه می دهند، ده درصدآن رابه عنوان هزینه یا حق استفاده و بهره برداری از نرم افزارآرمش،به شرکت بپردازند.
  2) سفیران به شرکت اجازه می‌دهندکه هر زمان بنا به تشخیص و تدابیر و تمهیدات مدیریت مجموعه، میزان مبلغ کارمزد مذکور در قرارداد همکاری تغییر داده شود،تعرفه جدید از طریق نرم‌افزار یا پیامک به سفیران اطلاع داده شده و قابل اعمال بوده وسفیران حق هرگونه اعتراضی را از خود سلب وساقط می نمایند.
  مدت قراداد
  قرارداد همکاری فوق ازتاریخ ثبت نام درنرم افزار به مدت یک سال ودر صورت اراده شرکت به ادامه همکاری، عقد منعقده بصورت اتوماتیک و بدون ضرورت به انجام تشریفاتی بصورت سالانه تمدید خواهد شد، مگر اینکه شرکت تمایل به همراهی با سفیر را نداشته باشد، که درصورت اخیر با انسداد حساب کاربری سفیر،  توسط شرکت، این امر به منزله عدم تمدید قرارداد و در نتیجه پایان و خاتمه همکاری قراردادی فیمابین تلقی میگردد.
  تعهدات سفیران
  1)سفیرمتعهد است حق استفاده و بهره برداری از نرم افزار آرمش را به شرکت پرداخت نماید. بدیهی است چنانچه سفیر در پرداخت حق شرکت بیش از یک ماه تاخیر نماید.
  2)سفیر موظف است تصاویر کارت و سند مالکیت وسیله نقلیه،کارت ملی،گواهینامه و بیمه نامه شخص ثالث خود را در اختیار شرکت قرار دهد.
  ۳)سفیر مکلف است نرم‌افزار آرمش را منحصرا ًدر وسیله نقلیه ای که در نرم افزاربه نام سفیر ثبت شده است به کار گیرد.
  4)سفیر موظف است صرفا مبلغ تعیین شده و ملحوظ نرم افزار آرمش را از مسافر و صاحبان بار و مرسوله مطالبه نماید، درخواست مازاد بر تعرفه، شرکت را مجاز خواهد نمود تا قرارداد همکاری را رأساً فسخ و حساب کاربری سفیر متخلف را مسدودنماید.
  5)سفیر متعهد می‌شود شماره تماس مسافران و صاحبان محموله و مرسوله را ذخیره ننموده و از برقراری تماسهای شخصی اجتناب نماید و در صورت وصول گزارش تخلف از این امر، سفیر پنج برابر درآمد حاصل از این سفرها رابه شرکت پرداخت خواهد نمود و مضافاً در صورت درخواست مسافر و یا صاحب محموله ومرسوله، موضوع از طریق مراجع قضایی نیز قابل پیگیری خواهد بود.
  6)سفیر موظف است پس از قبول سفر، در اسرع وقت در مبدا حاضر و تخلف و یا تأخیر غیر متعارف از این امر، شرکت مجاز خواهد بود ضمن دریافت هزینه سفر، خسارت ناشی از ورود ضرر مادی و معنوی به حسن شهرت شرکت را مطالبه نماید.اخذ وجوه موصوف نافی اعمال حق شرکت برای فسخ قرارداد و انسداد حساب کاربری در نرم افزار نخواهد بود. مضافاً تکرار لغو درخواست مسافران، پس از پذیرش آن، توسط سفیران نیز مشمول مقررات پیش بینی شده حکم مشروحه خواهد شد.
  7)سفیر متعهد است پس از قبول درخواست مسافر و یا صاحب محموله و مرسوله، شخصا ًدر محل متعینه حاضر و از اعزام فرد دیگری به جایگزینی خود اجتناب نماید.
  8)سفیر موظف بوده اعتبار تجاری شرکت را در همه حال در نظر داشته باشد. لذا برای حفظ این مهم، مکلف است حسن اخلاق، مراتب ادب، نزاکت، امانتداری، وضعیت پوشش ظاهری، متناسب بودن شرایط فنی و ظاهری وسیله نقلیه و... را در قبال مسافران و صاحبان محموله و مرسوله رعایت نماید.
  9)سفیر تصریحاً اعلام و تائید میکند که از قوانین مرتبط در حوزه فعالیت خود اعم از قوانین صنفی، مالیاتی، مالیات بر درآمد و... مطلع بوده و ملزم به رعایت آن ها بوده و چنانچه به علت مرعی قرار ندادن هر یک از مقررات موضوعه از سوی سفیر، نتیجتاً شرکت از سوی مراجع قضایی محکوم به پرداخت وجهی گردد، بدیهی است سفیر موظف بوده وجه پرداختی را به انضمام هزینه وکیل و ده درصد رقم کل را به عنوان جریمه به شرکت پرداخت نماید.
  10)سفیر موظف است در حین انجام سفر، تمامی قوانین حاکم در راهنمایی و رانندگی را رعایت نماید. مسئولیت جبران خسارت جانی و مالی وارده به مسافر و محموله و مرسوله حین سفر و یا اشخاص ثالث مرتبط ناشی از عدم رعایت این مقررات، به عهده سفیر خواهد بود و شرکت در این خصوص هیچ مسئولیتی نخواهد داشت. مضافا ًدر صورت بروز چنین حوادثی، شرکت مجاز خواهد بود خسارت وارده به اعتبارتجاری نرم افزاروشرکت را از سفیرمطالبه نماید.
  11)سفیرمتعهد می شود در بهره برداری از خدمات نرم افزاری آرمش، کلیه قوانین مرتبط جاری کشور را رعایت و از نرم افزار به صورت قانونی استفاده کند. در صورتیکه در طول مدت ارائه خدمات سفیر با این نرم افزار، تخلف یا جرمی مرتکب شود، شرکت هیچگونه مسئولیتی در قبال ایشان نداشته و مسلما ًبه لحاظ خسارت وارده به حیثیت تجاری شرکت را ازسفیر مطالبه خواهدنمود.
  12)سفیر با ثبت نام در نرم افزار آرمش و ایجاد حساب کاربری به نام خود، صحت انتساب کلیه پیام های صادره از حساب کاربری خویش را پذیرفته، لذا سفیر حق هرگونه اعتراض یا ادعایی را از خویش سلب و ساقط می نماید. سفیر می بایست تمامی تغییرات را که ازطریق نرم‌افزار یا پیامک به وی ارسال میشود، مطالعه و مصوبات جدید را رعایت نماید.
  13)سفیر اقرار می نماید با افتتاح حساب کاربری، صرفا ًمجوز استفاده و بهره برداری غیر انحصاری و غیر قابل انتقال و محدود از خدمات شرکت را دریافت داشته و بر همین اساس داعیه ای در مالکیت آن نداشته و ادعای هرگونه حقی نسبت به شرکت (اعم ازعلامت تجاری و یا کپی رایت وسایر حقوق مادی و معنوی آن) را از خود سلب و ساقط کرده و در همه حال متعهد به مراعات و حفظ حقوق معنوی شرکت ، نسبت به نرم افزار و ملحقات قانونی آن خواهد بود .
  14)نظر به اینکه شرکت صرفا خدمات نرم افزاری ارائه داده و روند ایجاد ارتباط میان سفیر و مسافر و صاحبان محموله و مرسوله را تسریع و تسهیل مینماید، لذا سفیر، منحصراً در مقام استفاده کننده از این خدمات نرم افزاری بوده، و بنا بمراتب نمی تواند مدعی رابطه استخدامی، پیمانکاری و مشارکت، و یا سایر حقوق متصوره نسبت به شرکت باشد.
  15)مسئولیت تمامی فعالیت های خدماتی که از طریق حساب کاربری انجام می‌شود مطلقا ًبه عهده سفیر میباشد لذا ضروری است سفیر در حفظ اطلاعات حساب کاربری خود از جمله نام کاربری و رمزعبور، حداکثر اهتمام و جدیت لازم را مبذول داشته و بدین طریق مانع استفاده دیگران از آن گردد. بدیهی است درصورت ظن دسترسی شخص ثالث به حساب کاربری، این مسئله از اهمیت بالایی برخوردار بوده و اقتضاء داشته سفیر در اسرع وقت نسبت به تغییر رمز عبور خود اقدام نماید یا بقید فوریت حساب کاربری مذکور را مسدود و مراتب را به شرکت اطلاع رسانی نماید.
  16) سفیر می‌پذیرد قرارداد حاضر، یک عقد قابل فسخ و جایز از سوی شرکت بوده و شرکت هر زمان به صلاحدید خود و بدون نیاز به تدارک و ارائه هرگونه دلیلی،رأساً انفساخ قرارداد همکاری را بنحو مقتضی اعلان وحساب کاربری سفیر را مسدود نموده و مانع استفاده و بهره برداری ویاز خدمات شرکت گردد.
  17)درصورت کاهش امتیاز سفیران (از 5 امتیاز، 3.5 به پایین) بدواً از طریق پیامک یا تیم پشتیبانی تذکر داده می شود و در  صورت تکرار آن، حساب کاربری به مدت ۷۲ ساعت به حالت تعلیق درآمده ودر صورت عدم اصلاح، حساب کاربری مسدود خواهد شد.
  18)سفیر متعهد است شماره کارت بانکی خود را در حساب کاربری وارد نماید تا در مواردی که هزینه های سفر به صورت نقدی نزد مسافر و یا صاحب محموله و مرسوله موجود نبود، از طریق انتقال کارت به کارت عضو شتاب، به حساب سفیر واریز شود و سفیر در صورت عدم دریافت وجه کرایه سفر به هر دلیلی، حق هرگونه اعتراض نسبت به شرکت را از خود سلب و ساقط نموده و موظف به تأمین و کارسازی و پرداخت تعرفه متعینه در حق شرکت خواهد بود.
  ۱۹)با توجه به ضرورت اخذ استعلام عدم سوء پیشینه موثر کیفری و اعتیاد نداشتن به انواع مواد مخدر و مشروبات الکلی و روان گردانها در احراز صلاحیت فردی سفیر، به عنوان شرط اساسی استفاده از نرم افزار آرمش، سفیر بدینوسیله اعلام رضایت می نماید که شرکت اقدامات لازم را برای بررسی وضعیت عمومی شخص متقاضی، از طریق مراجع ذیربط تحصیل نماید. بدیهی است در صورت عدم تایید صلاحیت سفیر از سوی مراجع ذیصلاح، شرکت نسبت به انسداد حساب کاربری مذکور اقدام خواهد نمود‌.
  20) نظر به اینکه شرکت با ارائه خدمات نرم افزاری به عنوان یک پل ارتباطی عمل کرده و صرفاً ارتباط میان مسافران متقاضی سفر و صاحبان محموله و مرسوله و سفیران را تسریع و تسهیل می نماید، ضامن صلاحیت رفتار و اعمال مسافران و یا صاحبان محموله و مرسوله در مقابل سفیر نبوده و هیچ گونه مسئولیت حقوقی و کیفری در این خصوص و اتفاقات حین سفر نخواهد داشت. سفیر با نصب نرم افزار و استفاده و بهره برداری از آن و امضای ذیل قرارداد،اقرارمی نماید که در این خصوص اطلاع و آگاهی کامل را دارد.
  21)سفیر می‌بایست  در حمل و نقل بار و ارسال و مراسلات مرسوله به کلیه نکات مندرج در ذیل این ماده توجه کافی و وافی نموده و به مفاد آن عمل نماید.
  حمل و نقل بار و مرسوله با وزن تقریبی حداکثر ۲۵ کیلو و ابعاد ۴۵ × ۴۵ × ۴۵ سانتیمتر برای بار ارسالی توسط موتورسیکلت و حمل و نقل بار با وزن تقریبی ۴۰۰ تا حداکثر ۶۰۰ کیلوگرم برای وانت بارهای کوچک (پیکان وانت، پراید وانت، آریسان وانت و... ) و وزن تقریبا ًیک تا دو تن حداکثر برای وانت بارهای بزرگ (مزدا ،نیسان و...) و حداکثر ارزش مادی به مبلغ ده میلیون ریال برای تمامی وسایل نقلیه باربری می باشد. در صورت حمل و نقل بار با ارزش مادی بالاتر از این مبلغ، حضور صاحب بار الزامی می باشد. لازم به ذکر است سقف مجاز ارتفاع بارحمل شده تا یک متر بالاتر از کابین وانت بار بوده وسقف مجاز طول و عرض بار صرفا به میزان اتاقک حمل بار می باشد.
  22) عدم حمل و نقل هرگونه سند رسمی و یا غیر رسمی صادر شده از سوی ارگانهای دولتی و یا خصوصی نظیر شناسنامه ،کارت ملی، بیمه نامه و سند ملک و ...
  23) عدم حمل و نقل اسناد و اشیاء قیمتی نظیر طلا، جواهرات، سکه، اجناس عتیقه، اسناد تعهدآور بانکی شامل چک، سفته، برات و ... وجه نقد، مسکوکات رایج داخلی و خارجی و ...
  24)عدم حمل و نقل هرگونه بار و مرسولات غیر مجاز نظیر اجناس قاچاق، مشروبات الکلی، موادمخدر، مواد روانگردان، انواع سلاح اعم ازسرد و گرم، مواد اشتعال زا، آلات ارتکاب جرم و لوازم غیر مجاز و....
  25) عدم حمل و نقل مواد فاسد و ضایع شدنی.
  26)عدم حمل و نقل هرگونه حیوان اعم از اهلی و وحشی و مضافاً لاشه آنها.
  27)حمل و نقل هرگونه بار که نیاز به اخذ مجوز از مراجع ذیصلاح داشته باشد( نظیر نخاله های ساختمانی، مواد شیمیایی و ...) صرفاً با ارائه مجوز مربوطه از سوی مشتری و روئیت آن توسط سفیر امکان‌پذیر است. 
  28) حمل نقل بار و مرسولات بدون حضور صاحب بار ممنوع و در صورت حمل ونقل، تمام عواقب آن بعهده سفیر می باشد.
  29)سفیر متعهد میگردد که در صورت بروز هرگونه موانع در جریان حمل و نقل و تحویل مرسولات اعم از (توقیف قانونی، سرقت، تصادف، بیماری، نقص وسیله نقلیه و غیره را ... به طور کلی بروز هرگونه حادثه) بلافاصله مراتب را سریعاً به مشتری اعلام و در صورت لزوم به مراجع قضایی و انتظامی اعلام و منتظر دستورات مقامات فوق الذکرباشد.
  30) سفیر متعهد می گردد در حین انجام سفر و حمل و نقل مرسولات همواره از تجهیزات اختصاصی نقلیه،  نظیر کمربند و کلاه ایمنی  و... استفاده نماید.
  31)سفیر می‌بایست در طول مسیر و مسافت از مبدا تا مقصد رعایت امانت را نموده و در حفظ و نگهداری مرسوله اقدامات لازم را انجام دهد. مسئولیت هرگونه ایراد و خسارات وارده بر بار و مرسوله ناشی از سهل انگاری در ارائه خدمات (به استثنای قوای قاهره) بر عهده سفیر بوده و الزاماً مسئول جبران و پرداخت خسارتمربوطه خواهد بود. بدیهی است اثبات وقوع قوای قاهره که موجب رفع مسئولیت قانونی گردد بر عهده سفیر می باشد.
  32)تخلیه بار بر عهده مشتری بوده و سفیر صرفاً مسئولیت حمل و نقل بار و نظارت بر چیدمان آن و رعایت مسائل ایمنی اجناس را دارد.
  33)چنانچه سفیر در حمل و نقل مرسوله رعایت امانتداری را ننموده و عمدا ًو یا در نتیجه تقصیر، مرتکب تخلفی گردد شرکت مجاز در لغو همکاری با نامبرده خواهد بود. بدیهی است جبران و پرداخت خسارت وارده شرکت و اشخاص ثالث بر عهده سفیر می باشد.
  تبصره: سفیر متعهد می گردد چنانچه در تمامی ابناد مذکور در قرارداد، تخلفی مرتکب که موجب ورود خسارات و ضرر و زیانهای قابل توجهی شود، شرکت حق خواهد داشت بمنظور استیفاء و وصول خسارت با طرح دعوی نزد مراجع صالحه قضایی نسبت به توقیف وسیله نقلیه اقدام نماید.
  تبصره: بندهای 21 قرارداد تا بند33 قرارداد، مخصوص سفیران موتورسیکلت و وانت بار می باشد و تمامی بندهای این قرارداد مشترکاً لازم الاجرا می باشد.
  تذکر:
  درصورت تخلف سفیر ازمفادهریک ازابنادقراردادمبلغ وجه التزام پانزده میلیون تومان که در فروض مختلف پیش بینی شده درحق سفیر کارسازی خواهد شدوسفیر اقرارمینماید نسبت به این موضوع اطلاع وآگاهی کامل داشته وحق هرگونه اعتراض را از خود صلب وساقط مینماید.
  ماده سوم  : لغوهمکاری
  1)سفیران متعهد می شوند که با کاربران و مشتریان شرکت به صورت فردی وارد قرارداد نشده و در صورت تخلف از این امر، موظف بپرداخت جریمه نقدی بمبلغ دو میلیون ریال برای نوبت اول وشش میلیون ریال برای مرحله دوم می باشد، بدیهی است تکرار این موضوع موجب اقدام قانونی شرکت و همچنین انسداد حساب کاربری سفیر متخلف خواهد شد.
  2)سفیر متعهد میگردد در صورت تغییر وسیله نقلیه موضوع را در اسرع وقت به شرکت اعلام و مدارک مورد نیاز را به واحد بایگانی و نگهداری اسناد شرکت ارائه نماید و مضافا ًفعالیت با حساب کاربری و ثبت نام قبلی ممنوع بوده وعواقب ناشی از این عمل به عهده سفیر متخلف می باشد.
  3)سفیر متعهد می گردد که در صورت بروز هرگونه مشکل، قبل از انجام هر اقدام و یا اتخاذ تصمیم شخصی، ابتدا با واحد پشتیبانی شرکت تماس و با طرح مشکل و اخذ ارائه طریق های تیم مذکور، تعیین تکلیف نماید.
  4)هرگونه ثبت سفارش غیر واقعی از ناحیه سفیر منفردا ًو یا با همکاری اشخاص و ثبت اتمام سفر به هر عنوان که موجب اخذ امتیاز غیر واقعی و یا درآمد گردد، در مرتبه اول دو میلیون ریال و در مرتبه دوم شش میلیون ریال جریمه دریافت و مرتبه سوم منجر به مسدود شدن حساب کاربری سفیر از سوی شرکت می‌شود.
  تعهدات شرکت
  ۱)شرکت متعهد است کلیه آموزش های لازم جهت استفاده بهینه از نرم افزار آرمش را به صورت رایگان به سفیر ارائه دهد.
  ۲)شرکت متعهد است با اطلاع از بروز نقص در نرم افزار، در اسرع وقت نسبت به پیگیری و حل و فصل آن اقدام نماید و در تمام طول قرارداد موظف به پشتیبانی فنی از نرم افزار می باشد. بدیهی است چنانچه به هر دلیلی رفع نواقص با تأخیرات طولانی مدت مواجه شود، سفیر هیچگونه حقی پیرامون مطالبه خسارت و ضرر و زیان وارده احتمالی نخواهد داشت.
  قانون حاکم و شیوه حل اختلاف
  قرارداد حاضر از هر نظر تابع قوانین جمهوری اسلامی ایران بوده و در صورت بروز اختلاف، طرفین تلاش خواهند نمود اختلاف خود را به صورت مسالمت آمیز و از راه گفتگو،حل و فصل نمایند و در صورت عدم موفقیت، اختلافات از طریق مراجعه به مراجع قضایی و مؤسسات و اشخاص حقیقی و حقوقی واجد صلاحیت داوری استان تهران مطرح و تعیین تکلیف خواهدشد.
  `,
};
export const Button = () => {
  const history = useHistory();
  return (
    <div className="BlueButton" onClick={() => history.push("/status")}>
      <span>شرایط استفاده را خواندم و قبول دارم</span>
    </div>
  );
};
export const TermsTexts = () => {
  return (
    <div className="TermsTexts">
      <span>{texts.header}</span>
      <span className="HiddenScroll">{texts.body}</span>
    </div>
  );
};
