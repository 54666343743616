export const url = "https://armashapp.com/";
export const baseUrl = `${url}api/V1`;

export const urls = {
  verfiyCode: "Driver/Register/Code/Send",
  Login: "Driver/Register/Code/Validate",
  Register: "Driver/Register",
  UploadVideo: "Driver/Register/Video",
  Update: "Driver/Profile",
  getCity: "Cities",
  Contact: "Base/Category?content_name=contact_us",
};
