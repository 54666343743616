import React, { useState } from "react";
import { MobileHeader } from "../../Components/Common/MobileHeader";
import { Form } from "../../Components/Information/Form";
import { Button, checkData } from "../../Components/Information";
import { connect } from "react-redux";
import { registerData } from "../../Redux/Actions";
import { useHistory } from "react-router-dom";
import { showToast } from "../../Components/Toast";
import { setToken } from "../../Redux/Actions/Authentication";

const Information = ({ ...props }) => {
  const [Data, setData] = useState({});

  const [error, setError] = useState(false);
  const history = useHistory();
  return (
    <div className="container">
      <div className="mobileContainer">
        <div className="MobileHeader">
          <span
            className={"selectedtext"}
            // onClick={() => history.push("/info")}
          >
            اطلاعات فردی
          </span>
          <span>{"<"}</span>
          <span
            onClick={() =>
              checkData(
                Data,
                setError,
                props.setRegister,
                history,
                props.registerData
              )
            }
          >
            بارگذاری مدارک
          </span>
          <span>{"<"}</span>
          <span>قوانین و مقررات</span>
          <span>{"<"}</span>
          <span

          // onClick={() => history.push("/record")}
          >
            احراز هویت
          </span>
        </div>

        <Form
          registerData={props.registerData}
          Data={Data}
          setData={setData}
          error={error}
          setError={setError}
          mobile={props.mobile}
        />
        <Button
          Data={Data}
          token={props?.token}
          setToken={props?.setToken}
          setError={setError}
          setRegister={props.setRegister}
          registerInfo={props.registerData}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRegister: (data) => dispatch(registerData(data)),
    setToken: (token) => dispatch(setToken(token)),
  };
};
const mapStateToProps = (state) => ({
  mobile: state.auth.mobile,
  token: state.auth.token,
  registerData: state.auth.registerData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Information);
