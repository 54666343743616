import React from "react";
import { useHistory } from "react-router-dom";
const texts = {
  header: `به منظور احراز هویت، کافیست با زدن دکمه "ضبط ویدیو تایید هویت" یک ویدیو از خودتان بگیرید`,
  info:
    "تمام رخ جلوی دوربین قرار بگیرید. گواهینامه خود را رو به دوربین در دست نگه دارید طوری که واضح دیده شود ولی صورت شما را نپوشاند. سپس دکمه ضبط را فشار داده و ممتن زیر را بخوانید",
  text: `من ... (نام و نام خانوادگی خودتان) هستم و میخواهم به عنوان راننده در آرمَََََش ثبت نام کنم. تمامی شرایط و مقررات استفاده از آرمَش را خوانده ام و قبول دارم و`
};
export const Button = () => {
  const history = useHistory();
  return (
    <div className="BlueButton" onClick={() => history.push("./recording")}>
      <span>ضبط ویدیو تایید هویت</span>
    </div>
  );
};
export const ButtonExit = ({
  setLogin,
  setToken,
  setRegister,
  setHasVideo
}) => {
  const history = useHistory();
  return (
    <div
      className="BlueButtonExit"
      onClick={() => {
        setLogin(false);
        setToken(null);
        setRegister(null);
        setHasVideo(false);
        history.push("/");
      }}
    >
      <span>پایان فرآیند به روز رسانی اطلاعات</span>
    </div>
  );
};

export const RecordTexts = ({ hasVideo }) => {
  return (
    <div className="RecordTexts">
      <span className="Recordheader">
        {hasVideo
          ? "ویدئو تایید هویت شما موجود است، در صورت تمایل میتوانید ویدئو جدیدی ارسال کنید"
          : texts.header}
      </span>
    </div>
  );
};
