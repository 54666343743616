import React, { useState, useEffect } from "react";
import { showToast } from "../Toast";
import { SyncLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
const texts = {
  header: `به منظور احراز هویت، کافیست با زدن دکمه "ضبط ویدیو تایید هویت" یک ویدیو از خودتان بگیرید`,
  info:
    "تمام رخ جلوی دوربین قرار بگیرید. گواهینامه خود را رو به دوربین در دست نگه دارید طوری که واضح دیده شود ولی صورت شما را نپوشاند. سپس دکمه ضبط را فشار داده و متن زیر را بخوانید",
  text:
    "من ... (نام و نام خانوادگی خودتان) هستم و میخواهم به عنوان راننده در آرمَش ثبت نام کنم. تمامی شرایط و مقررات استفاده از آرمَش را خوانده ام و قبول دارم و این ویدئو را برای احراز هویت ارسال می کنم ",
};

const checkFile = (Data, token, setLoading, history, setStatus) => {
  setLoading(true);
  if (Data === "") {
    showToast("لطفا ابتدا فیلم را ضبط کنید");
    setLoading(false);
    return false;
  } else return upload(Data, token, setLoading, history, setStatus);
};

const upload = async (driver_video, token, setLoading, history, setStatus) => {
  fetch("https://armashapp.com/api/V1/Driver/Register/Video", {
    method: "POST",
    headers: {
      // Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: driver_video,
  })
    .then((response) => {
      setStatus(3);
      history.push("/status");
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
};

export const Button = ({ Data, token, setStatus }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  return (
    <div
      className="BlueButton"
      onClick={() => checkFile(Data, token, setLoading, history, setStatus)}
    >
      {loading ? (
        <SyncLoader
          size={10}
          //size={"150px"} this also works
          color={"white"}
          loading={true}
        />
      ) : (
        <span>ارسال ویدئو</span>
      )}
    </div>
  );
};

export const UploadVideoCard = ({
  file,
  setFile,
  id1,
  setSelectedFile,
  selectedFile,
}) => {
  const Selected = () => {
    let classname;
    if (file) {
      classname = "TickDiv TickDivSelected";
    } else classname = "TickDiv";
    return classname;
  };
  return (
    <div
      className="BlueButtonExitHalf"
      style={{ width: selectedFile ? "100%" : "48%" }}
      onClick={() => document.getElementById(id1).click()}
    >
      <input
        style={{ display: "none" }}
        id={id1}
        type="file"
        accept=".webm,.mp4,.MPG,.MP2,.MPEG,.MPE,.MPV,OGG,.MP4,.M4P,.M4V,.AVI,.WMV,.MOV"
        onChange={(event) => {
          if (event.target.files[0].type.includes("video")) {
            if (event.target.files[0].size > 8 * 1024 * 1024)
              showToast(
                "حجم ویدئو انتخاب شده زیاد است. لطفا ویدئو با حجم کمتر از 8 مگابایت انتخاب نمایید"
              );
            else {
              var formData = new FormData();
              setSelectedFile(true);
              formData.append("driver_video", event.target.files[0]);
              formData.append("type", event.target.files[0].type.split("/")[1]);
              setFile(formData);
            }
          } else {
            showToast("لطفا فایل ویدئو انتخاب نمایید");
          }
        }}
      />
      <span>انتخاب فایل</span>
    </div>
  );
};

export const VideoButton = ({ player, stopedRecord }) => {
  const [state, setState] = useState(1);
  const history = useHistory();
  useEffect(() => {
    stopedRecord && setState(4);
    // !stopedRecord && setState(3);
  }, [stopedRecord]);
  return (
    <div
      className="BlueButtonExitHalf"
      onClick={() => {
        if (state === 1) {
          player.record().getDevice();
          setState(2);
        } else if (state === 2) {
          player.record().start();
          setState(3);
        } else if (state === 3) {
          player.record().stop();
          setState(4);
        } else if (state === 4) {
          player.record().start();
          setState(3);
        }
      }}
    >
      <span>
        {state === 1
          ? "فعالسازی دوربین"
          : state === 2
          ? "شروع ضبط"
          : state === 3
          ? "پایان ضبط"
          : "ضبط مجدد"}
      </span>
    </div>
  );
};

export const RecordTexts = () => {
  return (
    <div className="RecordTexts Recording">
      <span className="Recordinfo">{texts.info}</span>
      <span className="Recordtext">{texts.text}</span>
    </div>
  );
};
