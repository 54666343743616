import React from "react";
import { useMediaQuery } from "react-responsive";

const links = [
  { name: "دانلود ها", id: "Downloads" },
  { name: "درباره ما", id: "AboutUs" },
  { name: "تماس با ما", id: "ContactUs" },
  { name: "ثبت نام رانندگان", id: "SignUp" },
];

const HeaderLinks = () => {
  return (
    <div className="headerLinksDiv">
      {links.map((item) => (
        <HeaderLinkItem name={item.name} id={item.id} />
      ))}
    </div>
  );
};

const HeaderLinkItem = ({ name, id }) => {
  function scroll(id) {
    window.scrollTo({
      top: document.getElementById(id).offsetTop,
      behavior: "smooth",
    });
  }
  return <span onClick={() => scroll(id)}>{name}</span>;
};

const HeaderText = () => {
  return (
    <div className="HeaderText">
      <img src={require("../../assets/images/Armash.png").default} alt="" />
      <span>
        سامانه هوشمند درخواست خودرو پل ارتباطی میان مسافران و رانندگان با نرم
        افزاری شیوا و روان
      </span>
    </div>
  );
};

const Header = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const imageSource =
    isTabletOrMobile && isPortrait
      ? require("../../assets/images/HeaderPhone.jpg").default
      : require("../../assets/images/Header.jpg").default;
  return (
    <div className="header">
      <img src={imageSource} alt="" />
      <HeaderLinks />
      {/* <img
        src={require("../../assets/images/Logo.png")}
        className="headerLogo"
        alt=""
      /> */}
      <HeaderText />
    </div>
  );
};

export default Header;
