import React, { useState } from "react";
import { useKey } from "./useKey";
import { store } from "../..";
import { login } from "../../Redux/Actions/Authentication";
const inputTexts = ["1", "2", "3", "4", "5"];

export default function CodeInput({ code, setCode }) {
  const [pressed, setPressed] = useState(false);
  let refs = {};
  const emptyFieldLast = useKey("Backspace", setPressed, pressed);

  const emptyInput = index => {
    if (index - 1 > 0) {
      refs[index - 1].value = "";
      onTextChangeLast(index - 1);
    } else {
      refs[index].value = "";
      onTextChangeLast(index);
    }
  };

  const loginFunction = () => {
    store.dispatch(login(false));
    // history.push("/");
  };

  const onTextChangeNext = index => {
    if (index + 1 < inputTexts.length) {
      refs[index + 1].focus();
    } else {
      loginFunction();
    }
  };
  const onTextChangeLast = index => {
    if (refs[index - 1]) {
      refs[index - 1].focus();
      setCode(code.slice(0, index) + " " + code.slice(index + 1, code.length));
      setPressed(false);
    } else {
      setCode(code.slice(0, index) + " " + code.slice(index + 1, code.length));
      setPressed(false);
    }
  };

  return (
    <div className="codeInput">
      {inputTexts.map((i, index) => {
        return (
          <input
            key={`#codeInput${index}`}
            type="number"
            pattern="[0-9]*"
            ref={ref => (refs[index] = ref)}
            placeholder={i}
            maxLength={1}
            onChange={v => {
              setCode(
                code.slice(0, index) +
                  v.target.value +
                  code.slice(index + 1, code.length)
              );
              v.target.value.length === 1
                ? onTextChangeNext(index)
                : onTextChangeLast(index);
            }}
            onKeyUp={v =>
              emptyFieldLast && v.target.value.length === 0 && emptyInput(index)
            }
          />
        );
      })}
    </div>
  );
}
