import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { HeaderImage } from "../../Components/ReceiveCode";
import { useHistory } from "react-router-dom";
import { login } from "../../Redux/Actions/Authentication";

const Status = ({ ...props }) => {
  const history = useHistory();
  return (
    <div className="container">
      <div className="mobileContainer">
        <div className="StatusPage">
          <img src={require("../../assets/images/Armash.png").default} alt="" />
          <span>با تشکر از نام نویسی شما در آرمش</span>
          <span>
            {props.confirmed
              ? "ثبت نام شما تایید شده است. می توانید از برنامه آرمش استفاده نمایید"
              : "ثبت نام شما در انتظار تایید توسط اپراتور می باشد. پس از تایید می توانید از برنامه آرمش استفاده نمایید"}
          </span>
        </div>
        <div
          className="BlueButton"
          onClick={() => {
            props.setLogin();
            history.push("/");
          }}
        >
          <span>بازگشت به صفحه اصلی سایت</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.auth.status,
    confirmed: state.params.confirmed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: () => dispatch(login(false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
