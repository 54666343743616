import React from "react";
import {
  Texts,
  Items,
  StepItem,
  Button,
  Documents
} from "../../Components/Steps";

const Steps = () => {
  return (
    <div className="container">
      <div className="mobileContainer">
        <Texts />
        <div className="StepsDiv">
          {Items.map((item, index) => (
            <StepItem number={item.number} text={item.text} />
          ))}
        </div>
        <Button />
        <Documents />
      </div>
    </div>
  );
};

export default Steps;
