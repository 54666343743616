import React, { useEffect, useState } from "react";
import { CircleLoader, ClipLoader } from "react-spinners";
import { ReactComponent as Camera } from "../../assets/icons/Camera.svg";
import { ApiCall } from "../../Redux/Actions/ApiCall";
import { showToast } from "../Toast";

function resizeImage(file) {
  return new Promise((resolve, reject) => {
    const fileName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        const width = 600;
        const height = (img.height / img.width) * 600;
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], fileName, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            resolve(file);
          },
          "image/jpeg",
          1
        );
      };
    };
    reader.onerror = (error) => console.log(error);
  });
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      let encoded = reader.result.toString();
      // if (encoded.length % 4 > 0) {
      //   encoded += "=".repeat(4 - (encoded.length % 4));
      // }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}
const UploadCard = ({
  twoImage,
  file,
  file2,
  setFile,
  setFile2,
  id1,
  id2,
  text,
  token,
  setIsUploaded,
  isUploaded,
  setRegister,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const uploadImage = () => {
    setisLoading(true);
    const temp = new FormData();
    temp?.append(id1, file);
    twoImage && temp?.append(id2, file2);
    ApiCall(
      "post",
      "Driver/Docs",
      temp,
      "UPLOAD",
      token,
      (e) => {
        setRegister(e?.data);
        let isUploadedTemp = [id1];
        if (twoImage) {
          isUploadedTemp = [...isUploadedTemp, id2];
        }
        setIsUploaded([...isUploaded, ...isUploadedTemp]);
        setisLoading(false);
      },
      () => {
        setisLoading(false);
      }
    );
  };

  useEffect(() => {
    if (twoImage) {
      if (!!file && !!file2 && file?.name && file2?.name) {
        uploadImage();
      }
    } else {
      if (!!file && file?.name) {
        uploadImage();
      }
    }
  }, [file, file2]);
  const Selected = () => {
    let classname;
    if (
      (!twoImage && file && isUploaded?.some((i) => i == id1)) ||
      (twoImage &&
        file &&
        file2 &&
        isUploaded?.some((i) => i == id1) &&
        isUploaded?.some((i) => i == id2)) ||
      (!!file && !file?.name) ||
      (!!file2 && !file2?.name)
    ) {
      classname = "TickDiv TickDivSelected";
    } else classname = "TickDiv";
    return classname;
  };
  return (
    <div className="uploadCard">
      <input
        style={{ display: "none" }}
        id={id1}
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={(event) => {
          if (event?.target?.files[0]?.type.includes("image")) {
            resizeImage(event.target.files[0]).then((e) => setFile(e));
          } else {
            showToast("لطفا فایل عکس انتخاب نمایید");
          }
        }}
      />
      {twoImage && (
        <input
          style={{ display: "none" }}
          id={id2}
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={(event) => {
            if (event?.target?.files[0]?.type?.includes("image")) {
              resizeImage(event.target.files[0]).then((e) => setFile2(e));
            } else {
              showToast("لطفا فایل عکس انتخاب نمایید");
            }
          }}
        />
      )}
      <div
        className="ImageContainer"
        onClick={() => document.getElementById(id1).click()}
      >
        {file ? (
          <img src={file?.name ? URL.createObjectURL(file) : file} alt={""} />
        ) : (
          <div className="twoImageText">
            <Camera />
            {twoImage && <span>روی کارت</span>}
          </div>
        )}
      </div>

      {twoImage && (
        <div
          className="ImageContainer"
          onClick={() => document.getElementById(id2).click()}
        >
          {file2 ? (
            <img
              src={file2?.name ? URL.createObjectURL(file2) : file2}
              alt=""
            />
          ) : (
            <div className="twoImageText">
              <Camera />
              <span>پشت کارت</span>
            </div>
          )}
        </div>
      )}
      <div className="uploadText">
        <span>{text}</span>
        {isLoading ? (
          <ClipLoader size={20} />
        ) : (
          <div className={Selected()}>{/* <Tick /> */}</div>
        )}
      </div>
    </div>
  );
};
export default UploadCard;
