import React, { Component } from "react";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";
import VideoRecorder from "react-video-recorder";

import Record from "videojs-record/dist/videojs.record.js";
const videoJsOptions = {
  controls: true,
  width:
    window.innerWidth < 768
      ? window.innerWidth * 0.95
      : window.innerWidth * 0.25,
  height: window.innerHeight * 0.35,
  fluid: false,
  controlBar: {
    // hide fullscreen and volume controls
    fullscreenToggle: false,
    recordToggle: false,
    deviceButton: false
  },
  plugins: {
    record: {
      audio: true,
      video: true,
      maxLength: 20,
      // videoEngine: "recordrtc",

      video: {
        width: 640,
        height: 480,
        facingMode: "user"
      },
      frameWidth: 640,
      frameHeight: 480,
      // videoMimeType: "video/mp4",
      debug: true
    }
  }
};
class VideoExample extends React.Component {
  componentDidMount() {
    // this.plugin = Record;
    this.player = videojs(this.videoNode, videoJsOptions, () => {
      var version_info =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version;
      videojs.log(version_info);
    });
    this.props.setPlayer(this.player);
    this.player.on("deviceReady", () => {});
    this.player.on("startRecord", () => {
      this.props.setStopedRecord(false);
      // setTimeout(() => {
      //   this.player.record().stop();
      // }, 5000);
    });
    this.player.on("finishRecord", () => {
      this.props.setStopedRecord(true);
      this.props.setPlayer(this.player);
      var formData = new FormData();
      formData.append("driver_video", this.player.recordedData);
      formData.append("type", this.player.recordedData.type.split("/")[1]);
      this.props.setUploadFile(formData);
    });
    this.player.on("error", (element, error) => {
      this.props.setError(true);
    });
    this.player.on("deviceError", () => {
      console.log(true);
      this.props.setError(true);
      console.error("device error:", this.player.deviceErrorCode);
    });
  }
  componentWillUnmount() {
    if (this.player) {
      // this.player.dispose();
    }
  }
  render() {
    return (
      <div data-vjs-player style={{ marginBottom: window.innerHeight * 0.23 }}>
        <video
          id="myVideo"
          ref={node => (this.videoNode = node)}
          className="video-js vjs-default-skin"
          playsInline
        ></video>
      </div>
    );
  }
}

export default VideoExample;
