import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
function RouteWrapper({ isLogin, component: Component, isPrivate, ...rest }) {
  const signed = isLogin;
  if (isPrivate && !signed) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};
RouteWrapper.defaultProps = {
  isPrivate: false
};

const mapStateToProps = state => {
  return {
    isLogin: state.auth.isLogin
  };
};

export default connect(mapStateToProps)(RouteWrapper);
