import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { ApiCall } from "../../Redux/Actions/ApiCall";
import { urls } from "../../Utils/url";
import { showToast } from "../Toast";

export const register = async (data, history, setToken, setLoading, setRegister) => {
  const body = { ...data };
  ApiCall(
    "post",
    urls.Register,
    body,
    "Upload",
    null,
    function (e) {
      setLoading(false);
      setRegister(e.data);
      setToken(e.data.access_token);
      history.push("/upload");
    },
    function (e) {
      console.log(e);
      setLoading(false);
    }
  );
};
export const update = async (data, history, setLoading, token, setRegister) => {
  const body = { ...data };

  ApiCall(
    "post",
    urls.Update,
    body,
    "update",
    token,
    function (e) {
      const Data = {
        first_name: e.data.first_name,
        last_name: e.data.last_name,
        mobile_number: e.data.mobile_number,
        birth_date: e.data.birth_date,
        address: e.data.user_role_driver.address,
        national_code: e.data.user_role_driver.national_code,
        shenas_no: e.data.user_role_driver.shenas_no,
        father_name: e.data.user_role_driver.father_name,
        postal_code: e.data.user_role_driver.postal_code,
      };
      setLoading(false);
      // if (e?.e.data.access_token) {
      //   setToken(e.data.access_token);
      // }
      setRegister({ ...e.data, ...Data });
      history.push("/upload");
    },
    function (e) {
      console.log(e);
      setLoading(false);
    }
  );
};

export const checkData = (data, setError, setRegister, history, registerInfo, setisLoading, token, setToken) => {
  setisLoading(true);
  const filtered = Object.keys(data)
    .filter((key) => key !== "postal_code" && key !== "image_location")
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  if (Object.values(filtered).includes("") || Object.values(filtered).includes(undefined)) {
    showToast("لطفا فرم را کامل کنید");
    setisLoading(false);
    setError(true);
    return false;
  } else {
    if (registerInfo !== null) {
      let Data = {};
      Data.user_role_driver = { ...registerInfo.user_role_driver };
      if (registerInfo?.user_role_driver && Object?.keys(registerInfo?.user_role_driver)?.length > 0) {
        update(data, history, setisLoading, token, setRegister);
      } else {
        register(data, history, setToken, setisLoading, setRegister);
      }
      setRegister({ ...data, ...Data });
    } else {
      register(data, history, setToken, setisLoading, setRegister);
      setRegister(data);
    }
    // history.push("/upload");
  }
};

export const Button = ({ Data, setError, setRegister, registerInfo, token, setToken }) => {
  const history = useHistory();
  const [isLoading, setisLoading] = useState(false);
  return (
    <div
      className="BlueButton"
      onClick={() => checkData(Data, setError, setRegister, history, registerInfo, setisLoading, token, setToken)}
    >
      {isLoading ? (
        <SyncLoader
          size={10}
          //size={"150px"} this also works
          color={"white"}
          loading={true}
        />
      ) : (
        <span>تایید و ادامه</span>
      )}
    </div>
  );
};
