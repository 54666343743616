import React from "react";
import { useHistory } from "react-router-dom";
import { store } from "../..";
import { ReactComponent as DescCar } from "../../assets/icons/DescCar.svg";
import { ReactComponent as DescPickup } from "../../assets/icons/DescPickup.svg";
import { ReactComponent as Descbike } from "../../assets/icons/DescBike.svg";
import { loginPage } from "../../Redux/Actions/Authentication";
const texts = {
  header: "درباره آرمش",
  desc:
    "آرمش سامانه هوشمند درخواست خودرو می باشد. که جهت تسهیل در امر حمل نقل درون شهری مسافر و مرسوله طراحی شده، این اپلیکیشن در اسفند ماه 1398 به شهروندان استان تهران و استان البرز معرفی شده و قصد دارد در تمام استان های کشور فعالیت نماید و تمامی عوامل از جمله مدیران ارشد و تیم برنامه نویسی، تیم طراحی، تیم فنی و تیم پشتیبانی همگی از جوانان با استعداد و خلاق وآینده ساز کشور عزیزمان ایران می باشند این مجموعه در نظر دارد با ارائه  خدمات نرم افزاری با ایجاد پل ارتباطی میان مسافران و رانندگان و ارائه خدماتی شیوا و روان نیاز های این دو قشر را از طریق نرم افزار آرمش بر طرف و در جهت رفاه حال این عزیزان تلاش نماید هدف ما جلب رضایت و امنیت وآرامش مردم غیور سرزمین ایران می باشد"
};

const descColItems = [
  {
    header: "وانت",
    text:
      "این سرویس جهت جابجایی مرسوله طراحی شده است وخودروهایی همچون پیکان وانت ،پراید وانت و اریسان وانت ،مزدا ونیسان در این طرح جهت ارائه خدمت به هم وطنان عزیز تلاش می کند. از مزایایی چون : به صرفه بودن هزینه سفر و رویت هزینه سفر قبل از درخواست سفرهای چند مقصده و توقف در محل و پیگیری آنلاین سفر از مبدا تا مقصد می باشد این سرویس در سفرهای درون شهری فعال است",
    icon: <DescPickup />
  },
  {
    header: "سواری",
    text:
      " جهت جابجایی مسافر از مبدا به مقصد طراحی‌ شده از مزایایی همچون سرعت و امنیت بالا ، رویت هزینه سفر قبل از درخواست ،سفرهایی تا سه مقصد و توقف در محل و پیگیری آنلاین از مبدا تا مقصد و به صرفه بودن هزینه سفر می باشد این سرویس در سفرهای درون شهری  فعال است",
    icon: <DescCar />
  },
  {
    header: "موتور سیکلت",
    text:
      "این خدمات جهت صرفه جویی در هزینه و زمان شهروندان عزیز طراحی شده است از مزایای این خدمات پشتیبانی رانندگان و مسافران به صورت 24ساعته از طریق تماس تلفنی و یا پیامک می باشد. از دیگر مزایای این سرویس  تحویل مرسوله در کوتاه ترین زمان ممکن درخواست سفر چند مقصده در خواست توقف در محل و پیگیری مسافر یا مرسوله از مبدأ تا مقصد به صورت آنلاین می باشد ضمنأ این سرویس در سفرهای درون شهری فعال است",
    icon: <Descbike />
  }
];

const DescriptionColumn = ({ Icon, headerText, text }) => {
  return (
    <div className="descriptionColumn">
      {Icon}
      <span className="descColHead">{headerText}</span>
      <span>{text}</span>
    </div>
  );
};

const Description = ({ ...props }) => {
  return (
    <div className="description" id="AboutUs">
      <span>{texts.header}</span>
      <span>{texts.desc}</span>
      <div className="descriptionColumnDiv">
        {descColItems.map((i, index) => {
          return (
            <DescriptionColumn
              headerText={i.header}
              Icon={i.icon}
              text={i.text}
              key={`#Description${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Description;
