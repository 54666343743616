import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
const options = [
  { value: "1", label: "تهران" },
  { value: "2", label: "مشهد" },
  { value: "3", label: "اصفهان" }
];
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    width: "100%",
    fontSize: 12,
    backgroundColor: "transparent",
    marginLeft: 20,
    border: "0 !important",
    boxShadow: "none",
    height: "100%"
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#008dd5" : state.isBlured ? "#2e2e2e" : "#707070",
    border: "none",
    width: "100%"
  }),
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    width: "100%"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    width: "100%"
  })
};
const FloatingInput = ({ ...props }) => {
  let refs = {};
  const [fieldActivate, setFieldActivate] = useState(
    props.value ? true : false
  );
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    (props.value !== "") & (props.value !== null) &&
      props.value !== undefined &&
      setFieldActivate(true);
  }, [props.value]);
  function activateField() {
    setFieldActivate(true);
    props.setError(false);
    setFocused(true);
  }
  function disableFocus(e) {
    if (
      (!props.select && e.target.value === "") ||
      (props.select && props.value === "")
    ) {
      setFieldActivate(false);
    }
    setFocused(false);
  }
  function updateInputValue(e) {
    !props.select ? props.setValue(e.target.value) : props.setValue(e);
    activateField(e);
    !props.select && e.preventDefault();
  }
  const labelClass = () => {
    var classname = "";
    if (focused && fieldActivate) {
      classname = "field-active";
    } else if (!focused && fieldActivate) {
      classname = "field-active-notFocus";
    } else classname = "";
    return classname;
  };

  const inputClass = () => {
    var classname = "";
    if (focused && fieldActivate) {
      classname = "floating-label border-active";
    } else if (!focused && fieldActivate) {
      classname = "floating-label border-Notactive";
    } else if (!focused && !fieldActivate && props.error) {
      classname = "floating-label border-error";
    } else classname = "floating-label";
    return classname;
  };
  return (
    <div
      className={
        props.size === 2
          ? "field-group-half"
          : props.size === 1
          ? "field-group-third"
          : "field-group"
      }
    >
      <label className={labelClass()} onClick={() => refs.input.focus()}>
        {props.labelShort && (focused || fieldActivate)
          ? props.labelShort
          : props.label}
      </label>
      {!props.select ? (
        <input
          // style={!fieldActivate && props.error && { borderColor: "red" }}
          ref={label => (refs.input = label)}
          className={inputClass()}
          type={props.type === "tel" ? "tel" : "text"}
          pattern={props.type === "tel" && "[0-9]*"}
          maxlength={props.length}
          value={props.value}
          onFocus={activateField}
          onBlur={disableFocus}
          onChange={updateInputValue}
        />
      ) : (
        <Select
          ref={label => (refs.input = label)}
          value={
            options.filter(i => parseInt(i.value) === parseInt(props.value))[0]
          }
          className={inputClass()}
          styles={customStyles}
          onFocus={activateField}
          onBlur={disableFocus}
          onChange={updateInputValue}
          placeholder=""
          options={props.cities ? props.cities : options}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cities: state.params.cities
  };
};

export default connect(mapStateToProps)(FloatingInput);
