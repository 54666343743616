import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Routes from "./Routes";
import history from "./Utils/history";
import { connect } from "react-redux";
import { setRatio } from "./Redux/Actions/Authentication";
function App({ ...props }) {
  var viewport_meta = document.getElementById("viewport_meta");
  var viewports = {
    resized: `user-scalable=no, width=device-width, initial-scale=1, height=${props.height}, minimum-scale=1, maximum-scale=1`
  };
  window.onresize = function() {
    viewport_meta.setAttribute("content", viewports.resized);
  };
  useEffect(() => {
    props.setRatio(window.innerHeight);
  }, []);
  return (
    <Router history={history}>
      <Routes />
      <ToastContainer autoClose={3000} rtl />
    </Router>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setRatio: ratio => dispatch(setRatio(ratio))
  };
};
const mapStateToProps = state => {
  return {
    height: state.auth.ratio
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
