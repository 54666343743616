import axios from "axios";
import { baseUrl } from "../../Utils/url";
import { showToast } from "../../Components/Toast";
import { errorHandling } from "./errorHandling";
const headerItems = (token) => {
  if (token)
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  else
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
};
const config = (method, url, data, token) => {
  if (method === "get")
    return {
      method: method,
      headers: headerItems(token),
      url: `${baseUrl}/${url}`,
    };
  else
    return {
      method: method,
      headers: headerItems(token),
      url: `${baseUrl}/${url}`,
      data: data,
    };
};

export const ApiCall = async (
  method,
  url,
  data,
  name = "response ->",
  token = null,
  callback,
  onError
) => {
  try {
    const response = await axios(config(method, url, data, token));
    const res = response.data;
    if (response.status === 200) {
      if (res.status === "successful" || res.status === "DONE") {
        // console.log(name, res);
        if (typeof callback == "function") callback(res);
      } else {
        const error = res.error_code;
        showToast(errorHandling(error));
        // console.log("error", res);
        if (typeof onError == "function") onError(error);
      }
    } else {
      // console.log(response);
      showToast(errorHandling(res.error_code));
      if (typeof onError == "function") onError(response);
    }
  } catch (error) {
    // console.log(error);
    showToast(errorHandling(error));
    if (typeof onError == "function") onError(error);
  }
};
