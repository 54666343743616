import React from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

export const Signup = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const imageSource =
    isTabletOrMobile && isPortrait
      ? require("../../assets/images/SignupMpbile.jpg")
      : require("../../assets/images/Signup.jpg");
  const history = useHistory();
  return (
    <div className="signUp" id="SignUp">
      <img src={imageSource.default} alt="" />
      <div className="signUpInner">
        <span className="signUpHead">ثبت نام رانندگان</span>
        <span className="signUpBody">به راحتی کسب درآمد کنید</span>
        <span
          className="signupBtn"
          onClick={() =>
            history.push({
              pathname: "/join",
            })
          }
        >
          ثبت نام
        </span>
      </div>
    </div>
  );
};
