import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Verify from "../Screens/CodeInput/index.js";
import Home from "../Screens/Home/Home";
import Receive from "../Screens/ReceiveCode";
import Steps from "../Screens/Steps/index.js";
import Information from "../Screens/Information/index.js";
import Upload from "../Screens/UploadPhotos/index.js";
import Terms from "../Screens/TermsAndConditions/index.js";
import Record from "../Screens/RecordVideo/index.js";
import Recording from "../Screens/RecordingPage.js/index.js";
import Status from "../Screens/Status";
export default function Routes() {
  return (
    <Switch>
      <Route path={"/"} exact component={Home} />
      <Route path={"/join"} component={Receive} />
      <Route path={"/verify"} component={Verify} />
      <Route path={"/steps"} component={Steps} isPrivate />
      <Route path={"/info"} component={Information} isPrivate />
      <Route path={"/upload"} component={Upload} isPrivate />
      <Route path={"/terms"} component={Terms} isPrivate />
      <Route path={"/status"} component={Status} isPrivate />
      <Route path={"/record"} component={Record} isPrivate />
      <Route path={"/recording"} component={Recording} isPrivate />
    </Switch>
  );
}
