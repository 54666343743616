import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { showToast } from "../Toast";
import { ApiCall } from "../../Redux/Actions/ApiCall";
import { urls } from "../../Utils/url";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
export const errors = {
  phoneIsEmpty: "لطفا شماره موبایل خود را وارد کنید",
  phoneNotValid:
    "شماره موبایل وارد شده صحیح نمی باشد. لطفا مجددا و با دقت وارد نمایید.",
};

export const validatePhone = (phone) => {
  var PHONE = /^([0]{0,1})([9]{1})([0-9]{9})$/;
  return PHONE.test(phone);
};

const sendCode = async (phoneNumber, history, setLoading) => {
  const body = { mobile_number: phoneNumber };
  ApiCall(
    "post",
    urls.verfiyCode,
    body,
    "sendVerifySms",
    null,
    function (e) {
      history.push("/verify");
      setLoading(false);
      // toast.success(e.data, {
      //   position: "top-center",
      //   autoClose: 5000
      // });
    },
    function (e) {
      setLoading(false);
    }
  );
};

export const onClickSendCode = (phoneNumber, history, setPhone, setLoading) => {
  setLoading(true);
  if (phoneNumber !== "") {
    if (validatePhone(phoneNumber)) {
      sendCode(phoneNumber, history, setLoading);
      setPhone(phoneNumber);
    } else {
      showToast(errors.phoneNotValid);
      setLoading(false);
    }
  } else {
    showToast(errors.phoneIsEmpty);
    setLoading(false);
  }
};

export const HeaderImage = () => {
  return (
    <div className="HeaderImage">
      <img
        src={require("../../assets/images/mobileHeader.png").default}
        alt=""
      />
      <span>به راحتی کسب درآمد کنید</span>
    </div>
  );
};

export const CodeTexts = () => {
  return (
    <div className="CodeTexts">
      <span>به پنل ثبت نام غیر حضوری آرمش خوش آمدید</span>
      <span>
        جهت ثبت نام در آرمش به عنوان راننده، لطفا شماره همراه خود را وارد نمایید
      </span>
    </div>
  );
};

export const Button = ({ phoneNumber, setPhone }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  return (
    <div
      className="BlueButton"
      onClick={() =>
        onClickSendCode(phoneNumber, history, setPhone, setLoading)
      }
    >
      {loading ? (
        <SyncLoader
          size={10}
          //size={"150px"} this also works
          color={"white"}
          loading={true}
        />
      ) : (
        <span>دریافت کد</span>
      )}
    </div>
  );
};
