import React from "react";
import { MobileHeader } from "../../Components/Common/MobileHeader";
import { TermsTexts, Button } from "../../Components/Terms";
const Terms = () => {
  return (
    <div className="container">
      <div className="mobileContainer">
        <MobileHeader selected={3} />
        <TermsTexts />
        <Button />
      </div>
    </div>
  );
};

export default Terms;
