import { useEffect } from "react";
export function useKey(key, setPressed, pressed) {
  const match = event => key.toLowerCase() === event.key.toLowerCase();
  const onDown = event => {
    if (match(event)) setPressed(true);
  };
  useEffect(() => {
    window.addEventListener("keydown", onDown);
    return () => {
      window.removeEventListener("keydown", onDown);
    };
  }, [key]);

  return pressed;
}
