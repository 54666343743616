import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, CodeTexts, HeaderImage } from "../../Components/ReceiveCode";
import { setCities } from "../../Redux/Actions";
import { ApiCall } from "../../Redux/Actions/ApiCall";
import { getMobile } from "../../Redux/Actions/Authentication";
import { urls } from "../../Utils/url";

const getCities = (setCities) => {
  ApiCall("get", urls.getCity, null, "Get Cities", null, function (e) {
    const data = e.data;
    const FormattedData = data.map((i) => {
      return {
        value: i.id,
        label: i.name,
      };
    });
    setCities(FormattedData);
  });
};

const Receive = ({ ...props }) => {
  useEffect(() => {
    getCities(props.setCities);
  }, []);
  const [phoneNumber, setPhoneNumber] = useState("");
  const browserNotSupported = () => {
    let browsers = [
      "iPhone",
      "iPod",
      "iPad",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad Simulator",
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
      "Pike v7.6 release 92",
      "Pike v7.8 release 517",
    ];
    const platform = window.navigator.platform;
    if (browsers.some((i) => platform === i)) {
      return true;
    } else return false;
  };
  return (
    <div className="container">
      <div className="mobileContainer">
        {/* {!browserNotSupported() ? ( */}
        <>
          <HeaderImage />
          <CodeTexts />
          <Button phoneNumber={phoneNumber} setPhone={props.setPhone} />
          <input
            type="number"
            pattern="[0-9]*"
            autoComplete={false}
            maxLength={11}
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            placeholder={"09xx xxx xx xx"}
          />
        </>
        {/* ) : (
          <div className="StatusPage">
            <img
              src={require("../../assets/images/Armash.png").default}
              alt=""
            />
            <span style={{ color: "red" }}>عدم امکان ثبت نام</span>
            <span>
              ضمن پوزش در حال حاضر دسترسی به سیستم ثبت نام غیر حضوری آرمش از
              طریق دستگاه های اپل ممکن نیست. لطفا با دستکاه دیگری وارد شوید
            </span>
          </div>
        )} */}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPhone: (phone) => dispatch(getMobile(phone)),
    setCities: (cities) => dispatch(setCities(cities)),
  };
};

export default connect(null, mapDispatchToProps)(Receive);
