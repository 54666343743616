import React, { useState, useEffect } from "react";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ApiCall } from "../../Redux/Actions/ApiCall";
import { urls } from "../../Utils/url";
import { connect } from "react-redux";
const texts = {
  companyNumber1: "021-36856342",
  companyNumber2: "021-36856412",
  address:
    "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است",
  company:
    "سامانه هوشمند درخواست خودرو پل ارتباطی میان مسافران و رانندگان با نرم افزاری شیوا و روان",
};

const getContent = (token, setData) => {
  ApiCall("get", urls.Contact, null, "Get Content", null, (e) =>
    setData(e.data.content)
  );
};

const FooterInfo = ({ token }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getContent(token, setData);
  }, []);
  return (
    <div className="footerInfo">
      <div className="ENamad">
        {/* <img src={require("../../assets/images/logo.png")} alt={""} />
        <img src={require("../../assets/images/enamad.png")} alt={""} /> */}
        <a
          target="_blank"
          href="https://trustseal.enamad.ir/?id=143854&amp;Code=ndvWq9kw7roeFQ8K5FwL"
        >
          <img
            src="https://Trustseal.eNamad.ir/logo.aspx?id=143854&amp;Code=ndvWq9kw7roeFQ8K5FwL"
            alt=""
            style={{ cursor: "pointer" }}
            id="ndvWq9kw7roeFQ8K5FwL"
          />
        </a>
      </div>
      <div className="footerCompanyInfo">
        {data.length > 0 &&
          data.map((i) => (
            <div>
              <span>{i.full_text}</span>
              <Phone />
            </div>
          ))}

        {/* <div>
          <span>{texts.address}</span>
          <Location />
        </div> */}
      </div>
    </div>
  );
};

const Footer = ({ token }) => {
  return (
    <div className="footer" id="ContactUs">
      <div>
        <img src={require("../../assets/images/Armash.png").default} alt="" />
        <span>{texts.company}</span>
      </div>

      <FooterInfo token={token} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};
export default connect(mapStateToProps)(Footer);
