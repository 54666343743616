import React, { useEffect } from "react";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import DownloadApp from "../../Components/Home/ApplicationDownload";
import Description from "../../Components/Home/Description";
import { Signup } from "../../Components/Home/DriverSign";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  useEffect(() => {
    window.onpopstate = function() {
      history.go(0);
    };
  }, []);
  return (
    <div className="container">
      <Header />
      <DownloadApp />
      <Description />
      <Signup />
      <Footer />
    </div>
  );
};

export default Home;
