export function getMobile(mobile) {
  return {
    type: "GET_MOBILE",
    mobile: mobile
  };
}

export function getCode(code, mobile) {
  return {
    type: "CODE",
    code,
    mobile
  };
}

export function loginPage(open) {
  return {
    type: "LOGIN_PAGE",
    open
  };
}

export function getUser(userInfo) {
  return {
    type: "GET_USER",
    userInfo
  };
}
export function setRatio(ratio) {
  return {
    type: "RATIO",
    ratio
  };
}

export function login(isLogin) {
  return {
    type: "LOGIN",
    isLogin
  };
}
export function setStatus(status) {
  return {
    type: "STATUS",
    status
  };
}

export function setToken(token) {
  return {
    type: "TOKEN",
    token
  };
}
