import React from "react";
import { useHistory } from "react-router-dom";

export const MobileHeader = ({ selected }) => {
  const history = useHistory();
  return (
    <div className="MobileHeader">
      <span
        className={selected === 1 && "selectedtext"}
        onClick={() => history.push("/info")}
      >
        اطلاعات فردی
      </span>
      <span>{"<"}</span>
      <span
        className={selected === 2 && "selectedtext"}
        onClick={() => history.push("/upload")}
      >
        بارگذاری مدارک
      </span>
      <span>{"<"}</span>
      <span
        className={selected === 3 && "selectedtext"}
        onClick={() => history.push("/terms")}
      >
        قوانین و مقررات
      </span>
      <span>{"<"}</span>
      <span
        className={selected === 4 && "selectedtext"}
        onClick={() => history.push("/record")}
      >
        احراز هویت
      </span>
    </div>
  );
};
