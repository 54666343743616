import React from "react";
import { ReactComponent as Android } from "../../assets/icons/Android.svg";
import { ReactComponent as IOS } from "../../assets/icons/IOS.svg";
import { ReactComponent as PlayStore } from "../../assets/icons/logotype.svg";
import { ReactComponent as MobileApp } from "../../assets/icons/appImage.svg";
const btns = [
  {
    text: "نسخه مسافر Android",
    icon: <PlayStore />,
    link: "https://play.google.com/store/apps/details?id=com.armash",
  },
  {
    text: "نسخه راننده Android",
    icon: <PlayStore />,
    link: "https://play.google.com/store/apps/details?id=com.armashdrv",
  },
  {
    text: "نسخه مسافر IOS",
    icon: <IOS />,
    link: "https://sibche.com/applications/%D8%A2%D8%B1%D9%85%D8%B4",
  },
  {
    text: "نسخه راننده IOS",
    icon: <IOS />,
    link: "https://sibche.com/applications/armash-driver",
  },
  {
    text: "نسخه مسافر Android(بازار)",
    icon: <Android />,
    link: "https://cafebazaar.ir/app/com.armash",
  },
  { text: "نسخه راننده Android(بازار)", icon: <Android />, link: "" },
];
const DownloadApp = () => {
  return (
    <div className="downloadApp" id="Downloads">
      <MobileApp />
      <div>
        <span className="downloadAppHeader">دانلود اپلیکیشن آرمش</span>

        <div>
          {btns.map((i, index) => {
            return (
              <DownloadBtn
                text={i.text}
                icon={i.icon}
                link={i.link}
                key={`#AppDonwload${index}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
const DownloadBtn = ({ text, icon, link }) => {
  return (
    <div
      className="downloadBtn"
      onClick={() => link !== "" && window.open(link, "_blank")}
    >
      {icon}
      <span>{text}</span>
    </div>
  );
};

export default DownloadApp;
