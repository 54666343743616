import React, { useEffect, useState } from "react";
import FloatingInput from "../Common/FloatingForm";
var moment = require("moment-jalaali");
export const Form = ({ ...props }) => {
  const [name, setName] = useState("");
  const [family, setFamily] = useState("");
  const [father, setFather] = useState("");
  const [IDnumber, setIDNumber] = useState("");
  const [ID, setID] = useState("");
  const [BirthDay, setBirthDay] = useState("");
  const [BirthMonth, setBirthMonth] = useState("");
  const [BirthYear, setBirthYear] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  useEffect(() => {
    let birth = moment(`${BirthDay}/${BirthMonth}/${BirthYear}`, "jYYYY/jM/jD");
    const Data = props.Data;
    if (props.registerData) {
      Data.image_location = props.registerData.image_location;
    }
    Data.first_name = name;
    Data.last_name = family;
    Data.father_name = father;
    Data.mobile_number = props.mobile;
    Data.national_code = IDnumber;
    Data.shenas_no = ID;
    Data.birth_date = birth.format("YYYY-M-D");
    Data.address = address;
    Data.city_id = city !== null && city.value ? city.value : city;
    Data.postal_code = postCode;
    props.setData(Data);
  }, [
    name,
    family,
    father,
    IDnumber,
    ID,
    BirthDay,
    BirthMonth,
    BirthYear,
    address,
    city,
    postCode,
  ]);
  useEffect(() => {
    const data = props.registerData;
    if (data && data !== null) {
      setName(data.first_name);
      setFamily(data.last_name);
      setFather(
        data.father_name ? data.father_name : data.user_role_driver.father_name
      );
      setID(data.shenas_no ? data.shenas_no : data.user_role_driver.shenas_no);
      setIDNumber(
        data.national_code
          ? data.national_code
          : data.user_role_driver.national_code
      );
      setBirthDay(
        moment(
          data.birth_date ? data.birth_date : data.user_role_driver.birth_date
        ).format("jYYYY")
      );
      setBirthMonth(
        moment(
          data.birth_date ? data.birth_date : data.user_role_driver.birth_date
        ).format("jMM")
      );
      setBirthYear(
        moment(
          data.birth_date ? data.birth_date : data.user_role_driver.birth_date
        ).format("jDD")
      );
      setPostCode(data.postal_code);
      setCity(data.city_id);
      setAddress(data.address ? data.address : data.user_role_driver.address);
    }
  }, [props.registerData]);
  return (
    <div className="InfoForm">
      <FloatingInput
        value={name}
        setValue={setName}
        error={props.error}
        setError={props.setError}
        labelShort={"نام"}
        label={"نام*"}
      />

      <FloatingInput
        value={family}
        setValue={setFamily}
        error={props.error}
        setError={props.setError}
        label={"نام خانوادگی*"}
        labelShort={"نام خانوادگی"}
      />
      <FloatingInput
        value={father}
        setValue={setFather}
        setError={props.setError}
        error={props.error}
        label={"نام پدر*"}
        labelShort={"نام پدر"}
      />
      <div className="BDayDIV">
        <FloatingInput
          value={ID}
          setValue={setID}
          setError={props.setError}
          error={props.error}
          type={"tel"}
          length={10}
          label={"شماره شناسنامه*"}
          labelShort={"شماره شناسنامه"}
          size={2}
        />
        <FloatingInput
          value={IDnumber}
          setError={props.setError}
          setValue={setIDNumber}
          length={10}
          type={"tel"}
          error={props.error}
          label={"کد ملی*"}
          labelShort={"کد ملی"}
          size={2}
        />
      </div>
      <span>تاریخ تولد</span>
      <div className="BDayDIV">
        <FloatingInput
          value={BirthDay}
          error={props.error}
          type={"tel"}
          setError={props.setError}
          setValue={setBirthDay}
          label={"سال(13xx)*"}
          labelShort={"سال"}
          length={4}
          size={1}
        />
        <FloatingInput
          value={BirthMonth}
          error={props.error}
          type={"tel"}
          setError={props.setError}
          setValue={setBirthMonth}
          label={"ماه*"}
          labelShort={"ماه"}
          size={1}
          length={2}
        />
        <FloatingInput
          value={BirthYear}
          setError={props.setError}
          type={"tel"}
          error={props.error}
          setValue={setBirthYear}
          label={"روز*"}
          labelShort={"روز"}
          length={2}
          size={1}
        />
      </div>
      <div className="BDayDIV">
        <FloatingInput
          value={postCode}
          setValue={setPostCode}
          type={"tel"}
          setError={props.setError}
          label={"کد پستی(اختیاری)"}
          labelShort={"کد پستی"}
          length={10}
          size={2}
        />
        <FloatingInput
          value={city}
          error={props.error}
          setError={props.setError}
          setValue={setCity}
          select={true}
          label={"شهر*"}
          labelShort={"شهر"}
          size={2}
        />
      </div>
      <FloatingInput
        value={address}
        error={props.error}
        setError={props.setError}
        setValue={setAddress}
        labelShort={"آدرس"}
        label={"آدرس (با ذکر خیابان، کوچه، پلاک، طبقه، واحد)*"}
      />
    </div>
  );
};
